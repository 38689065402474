import React from 'react'

import { Link } from 'gatsby'

const Footer = () => (
  <footer
    style={{
      background: '#009999',
    }}
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          maxWidth: 420,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <span style={{ paddingLeft: 8, paddingRight: 8 }}><Link to="/contact">Contact Us</Link></span>
        <span style={{ paddingLeft: 8, paddingRight: 8 }}><Link to="/privacy">Privacy Policy</Link></span>
        <span style={{ paddingLeft: 8, paddingRight: 8 }}><Link to="/terms">Terms & Conditions</Link></span>
      </div>
      <div style={{ fontSize: 14, color: '#dddddd' }}>
      © {new Date().getFullYear()} Relatable App, Inc. All rights reserved
      </div>
    </div>
  </footer>
)

export default Footer
