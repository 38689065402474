import React from 'react'

import { Link } from 'gatsby'

import Logo from '../../svg/weblogo.inline.svg'

const Header = () => (
  <header>
    <div>
      <Link to="/">
        <Logo />
      </Link>
    </div>
  </header>
)

export default Header
